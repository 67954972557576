<template>
  <div class="location">
    <div class="col-lg-12 d-flex p-0">
      <button
        v-if="!isDisable && !isCurrentUserMarketer"
        class="btn btn-info btn-md ml-auto mb-1"
        @click="openModal(true)"
      >
        {{ $t('location.new') }}
      </button>
    </div>
    <div class="row animated fadeIn">
      <Folding-card v-if="isShowMapsAndLatitudeLongitudeSettings" :headerTitle="$t('dashboard.locationsMap')" :isOpened="false">
        <template #body>
          <Map-for-locations
            @markerClick="onLocationsMapMarkerClick"
            @markerOverOrLeave="onLocationsMapMarkerOverOrLeave"
            :locationsWithCoords="locationsWithCoords"
          ></Map-for-locations>
        </template>
      </Folding-card>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="input-group input-group-sm mb-1">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                id="search"
                name="search"
                :placeholder="$t('general.search')"
                class="form-control"
                ref="search"
                v-model.trim="searchValue"
                @input="refreshLocationsList"
              />
              <span v-show="searchValue !== ''" class="clear-input text-muted">
                <i class="fa fa-remove" @click="() => (this.searchValue = '')"></i>
              </span>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="input-group input-group-sm filters__input">
              <span class="input-group-addon filters__field-icon">
                <i
                  class="fa fa-building-o"
                  :class="{ 'text-primary': filterBycompanyName.company !== 'All companys' }"
                ></i>
              </span>
              <select
                @change="refreshLocationsList"
                class="form-control form-control-sm filters__field"
                ref="locationsSelect"
                v-model="filterBycompanyName"
              >
                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
                <option v-for="company in companys" :value="company" :key="company.name">
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="locations-list">
          <div class="card">
            <!--            <div class="loader loader-backdrop" v-if="isLocationsLoading"></div>-->
            <div v-if="isLocationsLoading"><Loader-spinner centered></Loader-spinner></div>
            <div class="card-header">
              <i class="fa fa-align-justify" />
              {{ $t('location.list') }}
            </div>
            <div class="card-block card-block__table table-responsive">
              <!--                Тут карта с локациями для маркетолога-->
              <!--                  <map-for-locations v-else @markerClick = "onLocationsMapMarkerClick"-->
              <!--                       @markerOverOrLeave =  "onLocationsMapMarkerOverOrLeave"-->
              <!--                       :locationsWithCoords = 'locationsWithCoordsForCurrentMarketer'>-->
              <!--                  </map-for-locations>-->

              <!--              <table class="table location-table">-->
              <!--                <thead>-->
              <!--                  <th scope="col" class="text-nowrap align-middle">-->
              <!--                    {{ $t('general.name') }}-->
              <!--                    <i-->
              <!--                      class="text-primary fa"-->
              <!--                      :class="[sortOrder === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"-->
              <!--                      @click="handleSort"-->
              <!--                    />-->
              <!--                  </th>-->
              <!--                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.locId') }}</th>-->
              <!--                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.owner') }}</th>-->
              <!--                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.created') }}</th>-->
              <!--                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.manager') }}</th>-->
              <!--                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.description') }}</th>-->
              <!--                  &lt;!&ndash;<th>{{ $t('location.coords') }}</th>&ndash;&gt;-->
              <!--                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.address') }}</th>-->
              <!--                </thead>-->
              <!--                <tbody v-if="!isCurrentUserMarketer">-->
              <!--                  <tr :class="{ 'row-read-only': isDisable }">-->
              <!--                    <td>-->
              <!--                      <span class="text-primary">{{ rootLocation.name }}</span>-->
              <!--                    </td>-->
              <!--                    <td>-->
              <!--                      <b>{{ rootLocation.loc_id }}</b>-->
              <!--                    </td>-->
              <!--                    <td></td>-->
              <!--                    <td></td>-->
              <!--                    <td></td>-->
              <!--                    <td></td>-->
              <!--                    &lt;!&ndash;<td></td>&ndash;&gt;-->
              <!--                    <td></td>-->
              <!--                  </tr>-->

              <!--                  <tr-->
              <!--                    v-for="location in nonRootLocations"-->
              <!--                    v-if="location.name.includes(searchValue)"-->
              <!--                    :key="location.id"-->
              <!--                    @click="openModal(false, location)"-->
              <!--                    :class="{-->
              <!--                      'row-read-only': isDisable,-->
              <!--                      'location-selected-on-map': locationSelectedOnLocationsMap === location.id-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    <td>{{ location.name }}</td>-->
              <!--                    <td>-->
              <!--                      <b>{{ location.loc_id }}</b>-->
              <!--                    </td>-->
              <!--                    <td>{{ getUserNameByID(location.owner) }}</td>-->
              <!--                    <td>{{ location.create_at | moment }}</td>-->
              <!--                    <td>{{ getUserNameByID(location.manager) }}</td>-->
              <!--                    <td class="location-word-break">{{ location.description }}</td>-->
              <!--                    &lt;!&ndash;                    <td>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                      <div v-if="location.coords && (location.coords.lat || location.coords.lng)">&ndash;&gt;-->
              <!--                    &lt;!&ndash;                        <div&ndash;&gt;-->
              <!--                    &lt;!&ndash;                          class="badge badge-info">&ndash;&gt;-->
              <!--                    &lt;!&ndash;                          {{ $t('location.coordsLat') }}: {{ location.coords.lat }}&ndash;&gt;-->
              <!--                    &lt;!&ndash;                        </div>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                       <div&ndash;&gt;-->
              <!--                    &lt;!&ndash;                         class="badge badge-info">&ndash;&gt;-->
              <!--                    &lt;!&ndash;                         {{ $t('location.coordsLng') }}: {{ location.coords.lng }}&ndash;&gt;-->
              <!--                    &lt;!&ndash;                       </div>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                      </div>&ndash;&gt;-->
              <!--                    &lt;!&ndash;                    </td>&ndash;&gt;-->
              <!--                    <td class="location-word-break">{{ location.address }}</td>-->
              <!--                  </tr>-->
              <!--                </tbody>-->
              <!--                &lt;!&ndash;                Тут табличка с локациями для маркетолога&ndash;&gt;-->
              <!--                <tbody v-else>-->
              <!--                  <tr :class="{ 'row-read-only': isDisable }" @click="openModal(false, rootLocation)">-->
              <!--                    <td>-->
              <!--                      <span class="text-primary">{{ rootLocation.name }}</span>-->
              <!--                    </td>-->
              <!--                    <td>-->
              <!--                      <b>{{ rootLocation.loc_id }}</b>-->
              <!--                    </td>-->
              <!--                    <td>-->
              <!--                      <span class="text-primary">{{ getUserNameByID(rootLocation.owner) }}</span>-->
              <!--                    </td>-->
              <!--                    <td>-->
              <!--                      <span class="text-primary">{{ rootLocation.create_at | moment }}</span>-->
              <!--                    </td>-->
              <!--                    <td>-->
              <!--                      <span class="text-primary">{{ getUserNameByID(rootLocation.manager) }}</span>-->
              <!--                    </td>-->
              <!--                    <td class="location-word-break">-->
              <!--                      <span class="text-primary">{{ rootLocation.description }}</span>-->
              <!--                    </td>-->
              <!--                    <td class="location-word-break">-->
              <!--                      <span class="text-primary">{{ rootLocation.address }}</span>-->
              <!--                    </td>-->
              <!--                    <td></td>-->
              <!--                  </tr>-->

              <!--                  <tr-->
              <!--                    v-for="location in nonRootLocations"-->
              <!--                    v-if="location.name.includes(searchValue)"-->
              <!--                    :key="location.id"-->
              <!--                    @click="openModal(false, location)"-->
              <!--                    :class="{-->
              <!--                      'row-read-only': isDisable,-->
              <!--                      'location-selected-on-map': locationSelectedOnLocationsMap === location.id-->
              <!--                    }"-->
              <!--                  >-->
              <!--                    <td>{{ location.name }}</td>-->
              <!--                    <td>-->
              <!--                      <b>{{ location.loc_id }}</b>-->
              <!--                    </td>-->
              <!--                    <td>{{ getUserNameByID(location.owner) }}</td>-->
              <!--                    <td>{{ location.create_at | moment }}</td>-->
              <!--                    <td>{{ getUserNameByID(location.manager) }}</td>-->
              <!--                    <td class="location-word-break">{{ location.description }}</td>-->
              <!--                    <td class="location-word-break">{{ location.address }}</td>-->
              <!--                  </tr>-->
              <!--                </tbody>-->
              <!--              </table>-->

              <table class="table location-table">
                <thead>
                  <th scope="col" class="text-nowrap align-middle">
                    {{ $t('general.name') }}
                    <i
                      class="text-primary fa"
                      :class="[sortOrder === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"
                      @click="handleSort"
                    />
                  </th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.locId') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.created') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.description') }}</th>
                  <!--<th>{{ $t('location.coords') }}</th>-->
                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.companyCaptionForTable') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.innCaptionForTable') }}</th>
                  <th scope="col" class="text-nowrap align-middle">{{ $t('location.phoneCaptionForTable') }}</th>
                </thead>
                <tbody v-if="!isCurrentUserMarketer">
                  <tr :class="{ 'row-read-only': isDisable }">
                    <td>
                      <span class="text-primary">{{ rootLocation.name }}</span>
                    </td>
                    <td>
                      <b>{{ rootLocation.loc_id }}</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <!--                    <td></td>-->
                    <!--                    &lt;!&ndash;<td></td>&ndash;&gt;-->
                    <!--                    <td></td>-->
                  </tr>

                  <tr
                    v-for="location in nonRootLocationsFiltered"
                    :key="location.id"
                    @click="openModal(false, location)"
                    :class="{
                      'row-read-only': isDisable,
                      'location-selected-on-map': locationSelectedOnLocationsMap === location.id
                    }"
                  >
                    <td>{{ location.name }}</td>
                    <td>
                      <b>{{ location.loc_id }}</b>
                    </td>
                    <td>
                      <div>{{ location.create_at | moment }}</div>
                      <div v-if="getUserNameByID(location.owner) !== ''">
                        {{ $t('location.byUser') }} {{ getUserNameByID(location.owner) }}
                      </div>
                    </td>
                    <td class="location-word-break">{{ location.description }}</td>
                    <!--                    <td>-->
                    <!--                      <div v-if="location.coords && (location.coords.lat || location.coords.lng)">-->
                    <!--                        <div-->
                    <!--                          class="badge badge-info">-->
                    <!--                          {{ $t('location.coordsLat') }}: {{ location.coords.lat }}-->
                    <!--                        </div>-->
                    <!--                       <div-->
                    <!--                         class="badge badge-info">-->
                    <!--                         {{ $t('location.coordsLng') }}: {{ location.coords.lng }}-->
                    <!--                       </div>-->
                    <!--                      </div>-->
                    <!--                    </td>-->
                    <td class="location-word-break">{{ location.party.name }}</td>
                    <td class="location-word-break">{{ location.party.inn }}</td>
                    <td class="location-word-break">{{ location.party.phone }}</td>
                  </tr>
                </tbody>
                <!--                Тут табличка с локациями для маркетолога-->
                <tbody v-else>
                  <tr :class="{ 'row-read-only': isDisable }" @click="openModal(false, rootLocation)">
                    <td>
                      <span class="text-primary">{{ rootLocation.name }}</span>
                    </td>
                    <td>
                      <b>{{ rootLocation.loc_id }}</b>
                    </td>
                    <td>
                      <div>{{ location.create_at | moment }}</div>
                      <div v-if="getUserNameByID(location.owner) !== ''">
                        {{ $t('location.byUser') }} {{ getUserNameByID(location.owner) }}
                      </div>
                    </td>
                    <td class="location-word-break">
                      <span class="text-primary">{{ rootLocation.description }}</span>
                    </td>
                    <td class="location-word-break">{{ rootLocation.party.name }}</td>
                    <td class="location-word-break">{{ rootLocation.party.inn }}</td>
                    <td class="location-word-break">{{ rootLocation.party.phone }}</td>
                    <td></td>
                  </tr>

                  <tr
                    v-for="location in nonRootLocationsFiltered"
                    :key="location.id"
                    @click="openModal(false, location)"
                    :class="{
                      'row-read-only': isDisable,
                      'location-selected-on-map': locationSelectedOnLocationsMap === location.id
                    }"
                  >
                    <td>{{ location.name }}</td>
                    <td>
                      <b>{{ location.loc_id }}</b>
                    </td>
                    <td>
                      <div>{{ location.create_at | moment }}</div>
                      <div v-if="getUserNameByID(location.owner) !== ''">
                        {{ $t('location.byUser') }} {{ getUserNameByID(location.owner) }}
                      </div>
                    </td>
                    <td class="location-word-break">{{ location.description }}</td>
                    <td class="location-word-break">{{ location.party.name }}</td>
                    <td class="location-word-break">{{ location.party.inn }}</td>
                    <td class="location-word-break">{{ location.party.phone }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="col-lg-5" v-if="false">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-map-marker"></i>
            Location Map
          </div>
          <div class="card-block"><div class="map"></div></div>
        </div>
      </div>-->
    </div>
    <Modal
      title="Delete location"
      class="modal-danger"
      v-model="dangerModal"
      @ok="dangerModal = false"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('location.delete') }}</h4>
      <div class="modal-body p-0 mb-1 mt-1">{{ $t('location.deleteText') }}</div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="dangerModal = false">{{ $t('general.cancel') }}</button>
        <button type="button" class="btn btn-outline-danger" @click="deleteLocation">
          {{ $t('general.yesDelete') }}
        </button>
      </div>
    </Modal>

    <Modal
      v-model="isModalOpen"
      class="modal-info"
      effect="fade/zoom"
      :closeBtn="true"
      :backdrop="false"
      @click.native="handleModalClick"
    >
      <h4 slot="modal-title" class="modal-title">
        {{ isNew ? $t('location.new') : $t('location.edit') }}
      </h4>

      <form @submit.prevent="handleSave">
        <!--        имя-->
        <div class="form-group">
          <label for="path" class="required-mark">{{ $t('location.path') }}</label>
          <input
            id="path"
            name="location"
            type="text"
            class="input form-control root-location"
            aria-describedby="locationCaption"
            :class="{ 'is-danger': errors.has('location') }"
            :disabled="!isNew"
            v-model="location.name"
            v-validate="'required|location'"
            data-vv-validate-on="input"
          />
          <small id="locationCaption" class="text-muted location-help">
            {{ $t('location.descendant') }}
          </small>
          <span
            v-tooltip.top-center="{ content: errors.first('location') }"
            v-show="errors.has('location')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error" />
          </span>
        </div>
        <!--        <div class="form-group" v-if="!isNew">-->
        <!--          <label for="loc-id">{{ $t('location.locId') }}</label>-->
        <!--          <input-->
        <!--            id="loc-id"-->
        <!--            name="loc-id"-->
        <!--            type="text"-->
        <!--            class="input form-control root-location custom-loc-id-cursor"-->
        <!--            disabled-->
        <!--            v-model="location.loc_id"-->
        <!--          />-->
        <!--        </div>-->

        <!--        <div class="form-group">-->
        <!--          <label for="manager" class="">{{ $t('location.manager') }}</label>-->
        <!--          <select-->
        <!--            id="manager"-->
        <!--            name="manager"-->
        <!--            class="form-control"-->
        <!--            aria-describedby="managerCaption"-->
        <!--            v-model="location.manager"-->
        <!--            :disabled="!usersList.length"-->
        <!--          >-->
        <!--            <option v-for="user in usersList" :key="user.id" :value="user.id">-->
        <!--              {{ user.username }}-->
        <!--            </option>-->
        <!--          </select>-->
        <!--          <small id="managerCaption" class="text-muted location-help">-->
        <!--            {{ $t('location.managerCaption') }}-->
        <!--          </small>-->
        <!--        </div>-->
        <!--описание-->
        <div class="form-group">
          <label for="description">{{ $t('location.description') }}</label>
          <input
            :data-vv-as="$t('location.description')"
            id="description"
            name="description"
            type="text"
            class="input form-control"
            v-model="location.description"
            v-validate="'max: 70'"
            data-vv-validate-on="input"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('description') }"
            v-show="errors.has('description')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error" />
          </span>
          <span v-show="errors.has('description')" class="help is-danger">
            {{ errors.first('description') }}
          </span>
        </div>
        <div class="form-group">
          <!--        карта-->
          <div class="d-flex justify-content-between"  v-if="isShowMapsAndLatitudeLongitudeSettings">
            <div class="d-flex align-items-center">
              <h5 @click="toggleUndercutBlock('map')">
                <i v-if="blocksShowingInModal.map" class="icon-arrow-down"></i>
                <i v-else class="icon-arrow-right"></i>
                &nbsp;
                {{ $t('location.mapCaption') }}
              </h5>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <span>{{ $t('location.sameAsSelecteted') }}</span>
              </div>
              <div class="ml-1">
                <select v-model="sameAsSelectedForMapLocationId" @change="sameAsSelectedForMap" class="form-control">
                  <option :value="'none'"></option>
                  <option v-for="location in locationsItems" :key="location.id" :value="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--                <h6>{{ $t('location.mapCaption') }}</h6>-->
          <div v-show="blocksShowingInModal.map && isShowMapsAndLatitudeLongitudeSettings" class="form-group ml-2">
            <div class="form-group">
              <label for="addrSearchSelector">
                <strong>{{ $t('location.searchAddressCaption') }}</strong>
              </label>
              <SelectComponentForCompanyAndAddressSearch
                :in-modal="true"
                v-if="isModalOpen && addressSelectorShowing"
                id="addrSearchSelector"
                v-model="selectedAddressInSelector"
                class="w-100"
                option-id-name="place_id"
                track-by="display_name"
                :placeholder="$t('location.enterAddressToSearch')"
                :async-function="requestCoordsByAddress.bind(this)"
                @select="selectAddressInSelector"
                :noOptionsFound="$t('location.addressNotFound')"
              />
            </div>
            <div class="form-group">
              <label for="address">{{ $t('location.address') }}</label>
              <textarea
                id="address"
                name="address"
                type="text"
                class="input form-control"
                v-model="location.address"
                v-validate="'max: 300'"
                data-vv-validate-on="input"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('address') }"
                v-show="errors.has('address')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <div class="form-group" v-if="location.coords">
              <label for="lat">{{ $t('location.coordsLatFull') }}</label>
              <input
                id="lat"
                name="lat"
                step="0.0000000000001"
                type="number"
                class="input form-control"
                v-model.number="location.coords.lat"
                v-validate="'between:-90,90'"
                data-vv-validate-on="input"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('lat') }"
                v-show="errors.has('lat')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <div class="form-group" v-if="location.coords">
              <label for="lng">{{ $t('location.coordsLngFull') }}</label>
              <input
                id="lng"
                name="lng"
                step="0.0000000000001"
                type="number"
                class="input form-control"
                v-model.number="location.coords.lng"
                v-validate="'between:-180,180'"
                data-vv-validate-on="input"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('lng') }"
                v-show="errors.has('lng')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <div class="form-group">
              <Map-location
                @markerDrag="onMarkerDrag"
                :coords-lat="location.coords.lat || 0"
                :coords-lng="location.coords.lng || 0"
                :name="location.name"
                v-if="isModalOpen && blocksShowingInModal.map"
              ></Map-location>
            </div>
          </div>
          <div class="form-group" v-if="!isShowMapsAndLatitudeLongitudeSettings">
              <label for="address">{{ $t('location.address') }}</label>
              <textarea
                id="address"
                name="address"
                type="text"
                class="input form-control"
                v-model="location.address"
                v-validate="'max: 300'"
                data-vv-validate-on="input"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('address') }"
                v-show="errors.has('address')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
        </div>
        <!--        Компания aka party-->
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <h5 @click="toggleUndercutBlock('party')">
                <i v-if="blocksShowingInModal.party" class="icon-arrow-down"></i>
                <i v-else class="icon-arrow-right"></i>
                &nbsp;
                {{ $t('location.company') }}
              </h5>
            </div>
            <div
              v-if="
                errors.has('partyPhone') ||
                errors.has('partyName') ||
                errors.has('partyInn') ||
                errors.has('partyOgrn') ||
                errors.has('partyKpp')
              "
              class="d-flex align-items-center ml-1 mr-1"
            >
              <small style="color: red;">{{ $t('location.someFieldsHaveErrors') }}</small>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <span>{{ $t('location.sameAsSelecteted') }}</span>
              </div>
              <div class="ml-1">
                <select
                  v-model="sameAsSelectedForPartyLocationId"
                  @change="sameAsSelectedForParty"
                  class="form-control"
                >
                  <option :value="'none'"></option>
                  <option v-for="location in locationsItems" :key="location.id" :value="location.id">
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-show="blocksShowingInModal.party" class="form-group ml-2">
            <div class="form-group">
              <label for="companySearchSelector">
                <strong>{{ $t('location.searchСompanCaption') }}</strong>
              </label>
              <SelectComponentForCompanyAndAddressSearch
                :in-modal="true"
                v-if="isModalOpen && companySelectorShowing"
                id="companySearchSelector"
                v-model="selectedCompanyInSelector"
                class="w-100"
                option-id-name="value"
                track-by="nameForShowing"
                :placeholder="$t('location.enterCompanyNameToSearch')"
                :async-function="requestCompany.bind(this)"
                @select="selectCompanyInSelector"
                :noOptionsFound="$t('location.companyNotFound')"
              />
            </div>
            <!--            Phone-->
            <div class="form-group">
              <label for="path" :class="{'required-mark': isPartyPhoneFieldRequired}">{{ $t('location.phone') }}</label>
              <the-mask
                id="partyPhone"
                :mask="['# (###) ###-##-##', '## (###) ###-##-##', '### (###) ###-##-##']"
                name="partyPhone"
                type="tel"
                class="input form-control root-location"
                :class="{ 'is-danger': errors.has('partyPhone') }"
                v-model="location.party.phone"
                v-validate="validationRulesForPartyPhoneField"
                data-vv-validate-on="input"
                :data-vv-as="$t('location.phone')"
              />
              <small v-if="isPartyPhoneFieldRequired" id="partyPhoneCaption" class="text-muted location-help">
                {{ $t('location.phoneRequiredErrorMsg') }}
              </small>
              <span
                v-tooltip.top-center="{ content: errors.first('partyPhone') }"
                v-show="errors.has('partyPhone')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <!--            Name-->
            <div class="form-group">
              <label for="partyName">{{ $t('location.name') }}</label>
              <textarea
                id="partyName"
                name="partyName"
                type="text"
                v-validate="'max: 200'"
                class="input form-control root-location"
                :class="{ 'is-danger': errors.has('partyName') }"
                v-model="location.party.name"
                :data-vv-as="$t('location.name')"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('partyName') }"
                v-show="errors.has('partyName')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <!--            INN-->
            <div class="form-group">
              <label for="partyName">{{ $t('location.inn') }}</label>
              <input
                id="partyInn"
                name="partyInn"
                type="text"
                :class="{ 'is-danger': errors.has('partyInn') }"
                v-validate="'inn|max: 12'"
                class="input form-control root-location"
                v-model="location.party.inn"
                :data-vv-as="$t('location.inn')"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('partyInn') }"
                v-show="errors.has('partyInn')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>

            <!--            OGRN-->
            <div class="form-group">
              <label for="partyName">{{ $t('location.ogrn') }}</label>
              <input
                id="partyOgrn"
                name="partyOgrn"
                type="text"
                :class="{ 'is-danger': errors.has('partyOgrn') }"
                v-validate="'ogrn|max: 13'"
                class="input form-control root-location"
                v-model="location.party.ogrn"
                :data-vv-as="$t('location.ogrn')"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('partyOgrn') }"
                v-show="errors.has('partyOgrn')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
            <!--            KPP-->
            <div class="form-group">
              <label for="partyName">{{ $t('location.kpp') }}</label>
              <input
                id="partyKpp"
                name="partyKpp"
                type="text"
                v-validate="'kpp|max: 9'"
                :class="{ 'is-danger': errors.has('partyKpp') }"
                class="input form-control root-location"
                v-model="location.party.kpp"
                :data-vv-as="$t('location.kpp')"
              />
              <span
                v-tooltip.top-center="{ content: errors.first('partyKpp') }"
                v-show="errors.has('partyKpp')"
                class="help is-danger error_tooltip"
              >
                <i class="fa fa-warning text-error" />
              </span>
            </div>
          </div>
        </div>

        <!--        limits-->
        <div class="form-group">
          <!--        <h6>{{ $t('location.limits.header') }}</h6>-->
          <h5 @click="toggleUndercutBlock('limits')">
            <i v-if="blocksShowingInModal.limits" class="icon-arrow-down"></i>
            <i v-else class="icon-arrow-right"></i>
            &nbsp;
            {{ $t('location.limits.header') }}
          </h5>
          <div v-show="blocksShowingInModal.limits" class="form-group ml-2">
            <div class="row">
              <div
                v-for="limit of ['cpe', 'portal', 'heatmap', 'radar']"
                :key="limit"
                class="col-lg-3 col-md-6 col-sm-12 form-group"
              >
                <label for="limits">{{ $t(`location.limits.${limit}`) }}</label>
                <input
                  :id="`limits-${limit}`"
                  :name="`limits-${limit}`"
                  :readonly="!isRootAdmin"
                  type="number"
                  class="input form-control"
                  v-model.number="location.limits[limit]"
                  v-validate="'min_value: 0'"
                  data-vv-validate-on="input"
                />
                <span
                  v-tooltip.top-center="{ content: errors.first(`limits-${limit}`) }"
                  v-show="errors.has(`limits-${limit}`)"
                  class="help is-danger error_tooltip"
                >
                  <i class="fa fa-warning text-error" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <!--        <br />-->
        <!--        design-->
        <!--        <h6>{{ $t('location.interfaceColorsAndLogo') }}</h6>-->
        <div class="form-group mt-2">
          <h5 class="" @click="toggleUndercutBlock('design')">
            <i v-if="blocksShowingInModal.design" class="icon-arrow-down"></i>
            <i v-else class="icon-arrow-right"></i>
            &nbsp;
            {{ $t('location.interfaceColorsAndLogo') }}
          </h5>
          <div v-show="blocksShowingInModal.design" class="form-group ml-2">
            <div class="form-group">
              <h6>{{ $t('location.custom_logo') }}:</h6>
              <div class="custom-logo-wrapper">
                <div v-if="customLogoWrapperShow" class="custom-location-logo">
                  <label for="custom_logo">
                    <img
                      alt=""
                      class="custom-location-logo-img"
                      :src="location.custom_logo || '/static/img/no-image.png'"
                    />
                    <div class="custom-location-logo-edit">
                      <i class="icon-camera custom-location-logo-icon big"></i>
                      <i
                        class="fa fa-remove text-danger custom-location-logo-delete small"
                        @click.stop.prevent="deleteCustomLogo()"
                        data-action="delete"
                        v-if="location.custom_logo && location.custom_logo !== ''"
                      ></i>
                    </div>
                    <input
                      type="file"
                      id="custom_logo"
                      if="custom_logo"
                      @input="previewCustomLogo"
                      name="custom_logo"
                      class="form-control custom-location-logo-field"
                      v-validate="'image|size:300'"
                    />
                  </label>
                </div>
              </div>
              <div class="text-center">
                <span v-show="errorCustomLogoValidation" class="help is-danger">
                  {{ $t('location.img_upload_error') }}
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="switch switch-text switch-bee switch-primary mr-h">
                <input
                  v-model="location.custom_styles_enable"
                  type="checkbox"
                  class="switch-input"
                  :disabled="isDisable"
                />
                <span class="switch-label" />
                <span class="switch-handle" />
              </label>
              <span class="m-0 mt-1">{{ $t('location.custom_styles_enable') }}</span>
            </div>
            <div class="form-group d-flex flex-column interface-block" v-if="isModalOpen">
              <div>
                <div class="col-7 h5" @click="toggleShowCustomColorsBlock" role="button">
                  <i v-if="showCustomColorsBlock" class="icon-arrow-down"></i>
                  <i v-else class="icon-arrow-right"></i>
                  <span class="ml-1">{{ $t('location.custom_colors') }}</span>
                </div>
              </div>
              <div v-show="showCustomColorsBlock">
                <!-- header-->
                <div class="d-flex flex-column justify-content-center align-items-start mt-1">
                  <div>
                    <h6>{{ $t('location.header') }}</h6>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="headerbackgroundcolor">{{ $t('location.background_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.headerbackgroundcolor"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="headerbackgroundcolor"
                        v-model="location.custom_styles.headerbackgroundcolor"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('headerbackgroundcolor')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="headercolor">{{ $t('location.text_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.headercolor"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="headercolor"
                        v-model="location.custom_styles.headercolor"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('headercolor')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                </div>
                <!-- sidebar-->
                <div class="d-flex flex-column justify-content-center align-items-start mt-1">
                  <div>
                    <h6>{{ $t('location.sidebar') }}</h6>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="sidebarbackgroundcolor">{{ $t('location.background_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.sidebarbackgroundcolor"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="sidebarbackgroundcolor"
                        v-model="location.custom_styles.sidebarbackgroundcolor"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('sidebarbackgroundcolor')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="sidebarbackgroundactivelink">{{ $t('location.active_menu_item_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.sidebarbackgroundactivelink"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="sidebarbackgroundactivelink"
                        v-model="location.custom_styles.sidebarbackgroundactivelink"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('sidebarbackgroundactivelink')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="sidebarbackgroundhoverlink">{{ $t('location.hover_menu_item_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.sidebarbackgroundhoverlink"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="sidebarbackgroundhoverlink"
                        v-model="location.custom_styles.sidebarbackgroundhoverlink"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('sidebarbackgroundhoverlink')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="sidebarcolor">{{ $t('location.menu_item_text_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.sidebarcolor"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="sidebarcolor"
                        v-model="location.custom_styles.sidebarcolor"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('sidebarcolor')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                </div>
                <!--footer-->
                <div class="d-flex flex-column justify-content-center align-items-start mt-1">
                  <div>
                    <h6>{{ $t('location.footer') }}</h6>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="footerbackgroundcolor">{{ $t('location.background_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.footerbackgroundcolor"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="footerbackgroundcolor"
                        v-model="location.custom_styles.footerbackgroundcolor"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('footerbackgroundcolor')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                </div>
                <!--main block-->
                <div class="d-flex flex-column justify-content-center align-items-start mt-1">
                  <div>
                    <h6>{{ $t('location.main_block') }}</h6>
                  </div>
                  <div class="d-flex justify-content-between custom-color-wrapper">
                    <div class="custom-color-input-label">
                      <label for="bodybackgroundcolor">{{ $t('location.background_color') }}</label>
                    </div>
                    <div>
                      <input
                        v-model="location.custom_styles.bodybackgroundcolor"
                        type="text"
                        size="7"
                        class="switch-input custom-color-input"
                        readonly="readonly"
                        :disabled="isDisableCustomStyles"
                      />
                      <input
                        id="bodybackgroundcolor"
                        v-model="location.custom_styles.bodybackgroundcolor"
                        type="color"
                        class="switch-input custom-color-input-color"
                        :disabled="isDisableCustomStyles"
                      />
                      <i
                        role="button"
                        @click="clearCustomColor('bodybackgroundcolor')"
                        class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"
                        :disabled="isDisableCustomStyles"
                        :class="{ 'disable-delete-color': isDisableCustomStyles }"
                      ></i>
                    </div>
                  </div>
                  <!--              <div class="d-flex justify-content-between custom-color-wrapper">-->
                  <!--                <div class="custom-color-input-label">-->
                  <!--                  <label for="bodycolor">Цвет заголовков</label>-->
                  <!--                </div>-->
                  <!--                <div>-->
                  <!--                  <input v-model="location.custom_styles.bodycolor" type="text" size="7"-->
                  <!--                         class="switch-input custom-color-input" readonly="readonly"-->
                  <!--                         :disabled="isDisableCustomStyles"/>-->
                  <!--                  <input id="bodycolor" v-model="location.custom_styles.bodycolor" type="color"-->
                  <!--                         class="switch-input custom-color-input-color"-->
                  <!--                         :disabled="isDisableCustomStyles"/>-->
                  <!--                  <i role="button" @click="location.custom_styles.bodycolor = ''"-->
                  <!--                     class="fa fa-remove fa-lg mr-q text-danger ml-1f ns custom-color-delete-color"></i>-->
                  <!--                </div>-->
                  <!--              </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div slot="modal-footer" class="modal-footer">
        <button
          v-if="!isCurrentUserMarketer"
          type="button"
          class="btn btn-outline-success mr-auto"
          :disabled="errors.any() || muteEdit"
          @click="handleSave"
        >
          <span :class="{ invisible: muteEdit }">
            {{ isNew ? $t('general.create') : $t('general.save') }}
          </span>
          <span v-if="muteEdit" class="loader loader--mini" />
        </button>

        <button
          v-if="!isNew && !isCurrentUserMarketer"
          type="button"
          class="btn btn-outline-danger mr-1"
          @click="isDeleteModalOpen = true"
        >
          {{ $t('general.delete') }}
        </button>

        <button type="button" class="btn btn-outline-secondary" @click="cancelEditMode">
          {{ $t('general.close') }}
        </button>
      </div>
    </Modal>

    <Modal v-model="isDeleteModalOpen" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('general.confirmDeletingTitle') }}</h4>
      {{ $t('general.confirmDeletingText') }}
      <b>{{ location.name }}</b>
      <p class="mt-1">{{ $t('location.deleteLocationDescriptionMsg') }}</p>
      <div slot="modal-footer" class="modal-footer">
<!--        <button type="button" class="btn btn-secondary" @click="isDeleteModalOpen = false">-->
<!--          {{ $t('general.cancel') }}-->
<!--        </button>-->

<!--        <button type="button" class="btn btn-outline-danger" @click="deleteLocation">-->
<!--          {{ $t('general.yesDelete') }}-->
<!--        </button>-->
        <button :disabled="muteEdit" type="button" class="btn btn-secondary"
                @click="isDeleteModalOpen = false">
          {{ $t('general.cancel') }}

        </button>

        <button :disabled="muteEdit" type="button" class="btn btn-outline-danger"
                @click="deleteLocation">

          <span :class="{ invisible: muteEdit }">{{ $t('general.yesDelete') }}</span>
          <span v-if="muteEdit" class="loader loader--mini loader-danger-in-locations"/>
        </button>
      </div>
    </Modal>
    <!--    loc id для только что созданой локации-->
    <Modal title="Saved Location loc_id" v-model="savedLocId" class="modal-info" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('location.locId') }}</h4>
      <div class="text-center">
        <label for="new_loc_id_input">{{ $t('location.locId') }}:</label>
        <div class="d-flex">
          <input
            type="text"
            :value="`${savedLocId}`"
            class="form-control"
            id="new_loc_id_input"
            ref="new_loc_id_input"
            readonly
          />
          <button
            class="btn btn-primary"
            @click="copyUrlFromInput('new_loc_id_input')"
            :aria-label="this.$t('general.copy')"
          >
            <i class="fa fa-copy"></i>
          </button>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="savedLocId = false">Ok</button>
      </div>
    </Modal>

    <Close-dialog
      v-if="!isCurrentUserMarketer"
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { TheMask } from 'vue-the-mask';
import Modal from '../components/Modal.vue';
import locationService from '../services/locationService';
import usersService from '../services/usersService';
import helpers from '../helpers';
import CloseDialog from '../components/close-dialog.vue';
import FoldingCard from '../components/Universal/folding-card.vue';
import MapLocation from '../components/Maps/MapLocation.vue';
import MapForLocations from '../components/Maps/MapForLocations.vue';
// import MultiSelectComponent from '../components/Universal/select/multi-select-component.vue';
import SelectComponentForCompanyAndAddressSearch from '../components/Universal/select/SelectComponentForCompanyAndAddressSearch.vue';

// import portalProfilesService from '../services/portalProfilesService';

const watcher = new helpers.ChangesWatcher();

export default {
  name: 'Location',
  components: {
    MapLocation,
    Modal,
    CloseDialog,
    MapForLocations,
    FoldingCard,
    // MultiSelectComponent,
    SelectComponentForCompanyAndAddressSearch,
    TheMask
  },
  data() {
    return {
      addressSelectorShowing: true,
      companySelectorShowing: true,
      selectedAddressInSelector: {},
      selectedCompanyInSelector: {},
      sameAsSelectedForMapLocationId: 'none',
      sameAsSelectedForPartyLocationId: 'none',
      blocksShowingInModal: {
        party: false,
        limits: false,
        design: false,
        map: false
      },
      savedLocId: false,
      errorCustomLogoValidation: false,
      showCustomColorsBlock: false,
      customLogoWrapperShow: true,
      locationSelectedInDashboard: null,
      locationSelectedOnLocationsMap: '',
      isModalOpen: false,
      isDeleteModalOpen: false,
      isCloseModalOpen: false,
      isNew: true,
      dangerModal: false,
      location: { limits: {}, party: {} },
      sortOrder: 1,
      searchValue: '',
      filterBycompanyName: 'All companys',
      newLocation: {
        name: '',
        manager: '',
        description: '',
        id: '',
        address: '',
        coords: {
          lat: 0,
          lng: 0
        },
        limits: {
          cpe: 0,
          portal: 0,
          heatmap: 0,
          radar: 0
        },
        // Как это все работает (кастомные цвета для локации):
        // 1.
        // При компиляции scss файлов туда засовываются css переменные по умолчанию, заполненные переменными scss
        // из файла /static/scss/defaultsCustomCSSVarsForLocation.scss
        // На этом этапе они ни на что не влияют, поскоольку на этом этапе в css файлах стилей
        // эти css переменные не используются
        //
        // 2. В компоненте Header есть computed customCSSVarsForUserLocation. Оно смотрит на базовую локацию юзера и на список локаций.
        // Если у локации, с id как у базовой локации юзера включен флаг custom_styles_enable то она возвращает элементы из объекта
        // custom_styles приведенные к виду CSS переменных (вначале добавляется --имясвойства, все обрачивается в :root {})
        //
        // 3. На хуке mounted компонента Header происходит проверка есть ли что в customCSSVarsForUserLocation. Если что-то есть
        // то происходит следующее. В конец элемента header добавляется элемент style, содержащий в себе данные из пункта 2
        // (computed customCSSVarsForUserLocation). Эnо частично или полностью перезаписывает css  перменные из
        // /static/scss/defaultsCustomCSSVarsForLocation.scss (пункт 1)
        // 4. А далее, в конец все того-же header, происходит вставка линки на css файл "/static/css/customStylesForLocationRewriteCSS.css"
        // Вставка этого самого файла и меняет цвета (в нем выборочно переопределяются некоторые цвета css,
        // поскольку он - последний css вставляемый в готовый dom он может все перекрасить)
        // Внутри него как раз и используются значения css переменных из пункта 1 или 3
        // 5. Также есть функция helpers.clearCustomLocationCss, которая очищает весь вставленный
        // ранее кастомныый css, отрабатывает на хуке mounted компонента Header если в
        // this.customCSSVarsForUserLocation ничего нет (например вышли из логина как подчиненный,
        // в локации у которого есть кастомные цвета, а в локации текущего пользователя нет)
        // (Если для локации нет кастомного CSS, а в DOM он есть, то удаляем его)
        custom_styles_enable: false,
        custom_styles: {
          bodybackgroundcolor: '',
          // bodycolor: '',
          headerbackgroundcolor: '',
          headercolor: '',
          footerbackgroundcolor: '',
          sidebarbackgroundcolor: '',
          sidebarbackgroundactivelink: '',
          sidebarbackgroundhoverlink: '',
          sidebarcolor: ''
        },
        custom_logo: '',
        party: {
          formal_name: '',
          inn: '',
          kpp: '',
          name: '',
          ogrn: '',
          phone: ''
        }
      }
    };
  },
  filters: {
    moment(value) {
      return moment(value).format('D MMM YYYY, H:mm');
    }
  },
  watch: {
    location: {
      deep: true,
      handler() {
        watcher.tick();
      }
    },
    // nonRootLocations: function () {
    //   if (this.locationSelectedInDashboard !== null) {
    //     const selectedLocation = this.nonRootLocations.filter(loc => loc.id === this.locationSelectedInDashboard);
    //     if (selectedLocation[0]) {
    //       this.openModal(false, selectedLocation[0]);
    //       this.locationSelectedInDashboard = null;
    //     }
    //   }
    // },
    locationsItems() {
      if (this.locationSelectedInDashboard !== null) {
        const selectedLocation = this.locationsItems.filter((loc) => loc.id === this.locationSelectedInDashboard);
        if (selectedLocation[0]) {
          this.openModal(false, selectedLocation[0]);
          this.locationSelectedInDashboard = null;
        }
      }
    },
    locationDescription(newValue) {
      if (this.locationDescription !== undefined && newValue.length > 70) {
        setTimeout(() => {
          // this.errors.r
          this.errors.remove('description');
          this.location.description = this.location.description.slice(0, 70);
        }, 1000);
      }
    }
  },
  computed: {
    isShowMapsAndLatitudeLongitudeSettings() {
      return this.$store.getters.isShowMapsAndLatitudeLongitudeSettings;
    },
    validationRulesForPartyPhoneField() {
      if (this.isPartyPhoneFieldRequired) {
        return 'required';
      }
      return '';
    },
    isPartyPhoneFieldRequired() {
      return this.isBeeline;
    },
    isBeeline() {
      return this.$store.getters.isBeeline;
    },
    locationDescription() {
      return this.location.description;
    },
    nonRootLocationsFiltered() {
      try {
        let filtredLocation = JSON.parse(JSON.stringify(this.nonRootLocations));
        if (this.searchValue !== '') {
          filtredLocation = filtredLocation.filter((location) => {
            if (
              location.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              location.party.inn.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              location.party.phone.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              location.description.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              location.party.name.toLowerCase().includes(this.searchValue.toLowerCase())
            ) {
              return true;
            }
            return false;
          });
        }
        if (this.filterBycompanyName !== 'All companys') {
          let locationsId = [];
          if (this.filterBycompanyName.hasOwnProperty('locations') && this.filterBycompanyName.locations.length > 0) {
            locationsId = this.filterBycompanyName.locations;
          }
          filtredLocation = filtredLocation.filter((location) => {
            if (locationsId.includes(location.id)) {
              return true;
            }
            return false;
          });
        }
        return filtredLocation;
      } catch (e) {
        console.log(e);
        return this.nonRootLocations;
      }
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    isDisableCustomStyles() {
      return !this.location.custom_styles_enable;
    },
    locationsWithCoords() {
      return this.$store.state.locationsList.filter((location) => {
        if (location.coords.lat || location.coords.lng) {
          return true;
        }
        return false;
      });
    },
    locationsWithCoordsForCurrentMarketer() {
      return this.locationsWithCoords.filter(
        (location) => location.manager === this.$store.state.userData.id || this.$store.state.userData.base_location === location.id
      );
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    },
    muteEdit() {
      return this.$store.state.muteLocationEdit;
    },
    locationsItems() {
      return this.$store.state.locationsList;
    },
    rootLocation() {
      return this.locationsItems.find((item) => this.isRootLocations(item.id));
    },
    nonRootLocations() {
      return this.locationsItems.filter((item) => !this.isRootLocations(item.id));
    },
    nonRootLocationsForCurrentMarketer() {
      return this.nonRootLocations.filter((location) => location.manager === this.$store.state.userData.id);
    },
    usersList() {
      return this.$store.state.usersList || [];
    },
    isLocationsLoading() {
      return this.$store.state.loadingLocations || this.$store.state.loadingUsers;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isRootAdmin() {
      return this.$store.getters.isRootAdmin;
    }
  },
  methods: {
    refreshLocationsList() {
      // console.log('refreshed');
    },
    clearFilters() {
      this.searchValue = '';
      this.filterBycompanyName = 'All companys';
    },
    clearSelectedCompanyInSelector() {
      this.$set(this, 'selectedCompanyInSelector', {});
      this.companySelectorShowing = false;
      this.$nextTick(() => {
        this.companySelectorShowing = true;
      });
    },
    clearSelectedAddressInSelector() {
      this.$set(this, 'selectedAddressInSelector', {});
      this.addressSelectorShowing = false;
      this.$nextTick(() => {
        this.addressSelectorShowing = true;
      });
    },

    async requestCoordsByAddress(address) {
      return locationService.requestCoordsByAddress(address);
    },
    selectAddressInSelector() {
      // console.log('selected');
      // console.log(this.selectedAddressInSelector);
      if (
        this.selectedAddressInSelector.hasOwnProperty('lon') &&
        this.selectedAddressInSelector.hasOwnProperty('lat')
      ) {
        this.location.coords.lat = parseFloat(this.selectedAddressInSelector.lat);
        this.location.coords.lng = parseFloat(this.selectedAddressInSelector.lon);
      }
      if (this.selectedAddressInSelector.hasOwnProperty('display_name')) {
        this.location.address = this.selectedAddressInSelector.display_name;
      }
      this.clearSelectedAddressInSelector();
    },
    async requestCompany(searchQuery) {
      return locationService.requestCompany(searchQuery);
    },
    selectCompanyInSelector() {
      // console.log('selected');
      // console.log(this.selectedCompanyInSelector);

      if (this.selectedCompanyInSelector.hasOwnProperty('value')) {
        this.location.party.name = this.selectedCompanyInSelector.value;
      }

      if (this.selectedCompanyInSelector.hasOwnProperty('data')) {
        if (this.selectedCompanyInSelector.data.hasOwnProperty('inn')) {
          this.location.party.inn = this.selectedCompanyInSelector.data.inn;
        }
        if (this.selectedCompanyInSelector.data.hasOwnProperty('kpp')) {
          this.location.party.kpp = this.selectedCompanyInSelector.data.kpp;
        }
        if (this.selectedCompanyInSelector.data.hasOwnProperty('ogrn')) {
          this.location.party.ogrn = this.selectedCompanyInSelector.data.ogrn;
        }
        // if (this.selectedCompanyInSelector.data.hasOwnProperty('name')) {
        //   this.location.party.inn = this.selectedCompanyInSelector.data.ogrn;
        // }

        // а далее удаляем ошибки валидатора, еслои были.
        this.errors.remove('partyName');
        this.errors.remove('partyInn');
        this.errors.remove('partyOgrn');
        this.errors.remove('partyKpp');
      }
      this.clearSelectedCompanyInSelector();
    },
    sameAsSelectedForParty(event) {
      const locationTargetId = event.target.value;
      // console.log(locationTargetId);
      if (locationTargetId === 'none') {
        // устанавливаем настройки как для новой локации
        this.location.party = JSON.parse(JSON.stringify(this.newLocation.party));
      } else {
        const targetLocationSearched = this.locationsItems.filter((location) => location.id === locationTargetId);
        if (targetLocationSearched.length > 0) {
          // копируем из найденой
          this.location.party = JSON.parse(JSON.stringify(targetLocationSearched[0].party));
        } else {
          // устанавливаем настройки как для новой локации, если почему-то ничего не нашлось
          this.location.party = JSON.parse(JSON.stringify(this.newLocation.party));
        }
      }
    },
    sameAsSelectedForMap(event) {
      const locationTargetId = event.target.value;
      // console.log(locationTargetId);
      if (locationTargetId === 'none') {
        // устанавливаем настройки как для новой локации
        this.location.address = this.newLocation.address;
        this.location.coords = JSON.parse(JSON.stringify(this.newLocation.coords));
      } else {
        const targetLocationSearched = this.locationsItems.filter((location) => location.id === locationTargetId);
        if (targetLocationSearched.length > 0) {
          // копируем из найденой
          this.location.address = targetLocationSearched[0].address;
          this.location.coords = JSON.parse(JSON.stringify(targetLocationSearched[0].coords));
        } else {
          // устанавливаем настройки как для новой локации, если почему-то ничего не нашлось
          this.location.address = this.newLocation.address;
          this.location.coords = JSON.parse(JSON.stringify(this.newLocation.coords));
        }
      }
    },
    openAllUndercutBlocks() {
      for (const key of Object.keys(this.blocksShowingInModal)) {
        this.blocksShowingInModal[key] = true;
      }
    },
    // closeAllUndercutBlock() {
    //   for (key of Object.keys(this.blocksShowingInModal)) {
    //     this.blocksShowingInModal[key] = false;
    //   }
    // },
    closeAllUndercutBlock() {
      try {
        for (const key of Object.keys(this.blocksShowingInModal)) {
          this.blocksShowingInModal[key] = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    toggleUndercutBlock(key) {
      this.blocksShowingInModal[key] = !this.blocksShowingInModal[key];
    },
    copyUrlFromInput(inputRef) {
      this.$refs[inputRef].select();
      document.execCommand('copy');
    },
    clearCustomColor(colorName) {
      if (!this.isDisableCustomStyles) {
        this.location.custom_styles[colorName] = '';
      }
    },
    toggleShowCustomColorsBlock() {
      this.showCustomColorsBlock = !this.showCustomColorsBlock;
    },
    previewCustomLogo(event) {
      this.errorCustomLogoValidation = false;
      this.$validator.validate('custom_logo').then(
        (result) => {
          if (result) {
            showPhotoModal();
          } else {
            this.errorCustomLogoValidation = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );

      const that = this;
      function showPhotoModal() {
        const input = event.target;
        if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            that.location.custom_logo = e.target.result;
            that.changePhoto = true;
          };
          reader.readAsDataURL(input.files[0]);
          input.value = '';
        }
      }
      this.customLogoWrapperShow = false;
      this.$nextTick(() => {
        this.customLogoWrapperShow = true;
      });
    },
    deleteCustomLogo() {
      this.errorCustomLogoValidation = false;
      this.location.custom_logo = '';
    },
    onLocationsMapMarkerOverOrLeave(locationId) {
      this.locationSelectedOnLocationsMap = locationId;
    },
    onLocationsMapMarkerClick(locationId) {
      const location = this.locationsItems.find((item) => item.id === locationId);
      this.openModal(false, location);
    },
    onMarkerDrag(payloadCoords) {
      // console.log(payloadCoords);
      this.location.coords.lat = payloadCoords.lat;
      this.location.coords.lng = payloadCoords.lng;
    },
    openModal(isNew, location) {
      if (this.isDisable) {
        return;
      }
      this.sameAsSelectedForMapLocationId = 'none';
      this.sameAsSelectedForPartyLocationId = 'none';
      this.clearSelectedCompanyInSelector();
      this.clearSelectedAddressInSelector();
      this.errorCustomLogoValidation = false;
      this.$validator.reset();
      this.isModalOpen = true;
      if (isNew) {
        // this.openAllUndercutBlocks();
        this.closeAllUndercutBlock();
        this.isNew = true;
        this.location = JSON.parse(JSON.stringify(this.newLocation));
      } else {
        this.closeAllUndercutBlock();
        this.isNew = false;
        this.showCustomColorsBlock = false;
        // this.location = { ...location };
        // console.log(location)
        this.location = JSON.parse(JSON.stringify(location));
        if (!location.hasOwnProperty('custom_logo')) {
          this.$set(this.location, 'custom_logo', '');
        }
        if (!location.hasOwnProperty('custom_styles_enable')) {
          this.$set(this.location, 'custom_styles_enable', false);
        }
        this.location.coords = {};
        this.$set(this.location.coords, 'lat', location.coords.lat);
        this.$set(this.location.coords, 'lng', location.coords.lng);
        this.location.limits = {};
        this.$set(this.location.limits, 'portal', location.limits.portal);
        this.$set(this.location.limits, 'cpe', location.limits.cpe);
        this.$set(this.location.limits, 'heatmap', location.limits.heatmap);
        this.$set(this.location.limits, 'radar', location.limits.radar);
        // this.location.coords.lat = location.coords.lat;
        // this.location.coords.lng = location.coords.lng;
        this.$set(this.location, 'custom_styles', {});
        const availableStyles = Object.keys(this.newLocation.custom_styles);
        if (!location.custom_styles) {
          location.custom_styles = {};
        }
        availableStyles.forEach((styleName) => {
          if (!location.custom_styles[styleName]) {
            this.$set(this.location.custom_styles, styleName, '');
          } else {
            this.$set(this.location.custom_styles, styleName, location.custom_styles[styleName]);
          }
        });
        // if (!location.custom_styles) {
        //   this.location.custom_styles = {};
        // } else {
        //   this.location.custom_styles = {};
        //   if (!location.custom_styles.bodybackgroundcolor) {
        //     this.$set(this.location.custom_styles, 'bodybackgroundcolor', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'bodybackgroundcolor', location.custom_styles.bodybackgroundcolor);
        //   }
        //   // if (!location.custom_styles.bodycolor) {
        //   //   this.$set(this.location.custom_styles, 'bodycolor', '');
        //   // } else {
        //   //   this.$set(this.location.custom_styles, 'bodycolor', location.custom_styles.bodycolor);
        //   // }
        //   if (!location.custom_styles.headerbackgroundcolor) {
        //     this.$set(this.location.custom_styles, 'headerbackgroundcolor', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'headerbackgroundcolor', location.custom_styles.headerbackgroundcolor);
        //   }
        //   if (!location.custom_styles.headercolor) {
        //     this.$set(this.location.custom_styles, 'headercolor', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'headercolor', location.custom_styles.headercolor);
        //   }
        //   if (!location.custom_styles.footerbackgroundcolor) {
        //     this.$set(this.location.custom_styles, 'footerbackgroundcolor', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'footerbackgroundcolor', location.custom_styles.footerbackgroundcolor);
        //   }
        //   if (!location.custom_styles.sidebarbackgroundcolor) {
        //     this.$set(this.location.custom_styles, 'sidebarbackgroundcolor', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'sidebarbackgroundcolor', location.custom_styles.sidebarbackgroundcolor);
        //   }
        //   if (!location.custom_styles.sidebarbackgroundactivelink) {
        //     this.$set(this.location.custom_styles, 'sidebarbackgroundactivelink', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'sidebarbackgroundactivelink', location.custom_styles.sidebarbackgroundactivelink);
        //   }
        //   if (!location.custom_styles.sidebarbackgroundhoverlink) {
        //     this.$set(this.location.custom_styles, 'sidebarbackgroundhoverlink', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'sidebarbackgroundhoverlink', location.custom_styles.sidebarbackgroundhoverlink);
        //   }
        //   if (!location.custom_styles.sidebarcolor) {
        //     this.$set(this.location.custom_styles, 'sidebarcolor', '');
        //   } else {
        //     this.$set(this.location.custom_styles, 'sidebarcolor', location.custom_styles.sidebarcolor);
        //   }
        // }
      }
      watcher.reset();
    },
    handleSave() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.isNew) {
            this.createLocation(this.location);
          } else {
            this.updateLocation(this.location);
          }
        }
      });
    },
    cancelEditMode() {
      this.isModalOpen = false;
      this.isDeleteModalOpen = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    handleSort() {
      this.sortOrder = this.flipSort(this.sortOrder);
      this.getLocations();
    },
    flipSort(current) {
      return current === 1 ? -1 : 1;
    },
    isRootLocations(id) {
      const rootLocation = this.$store.state.userData.base_location;
      return rootLocation === id;
    },
    getLocations() {
      locationService.getLocations(this, {
        sort_by: {
          field: 'name',
          order: this.sortOrder
        }
      });
    },
    createLocation() {
      locationService.createLocation(this, this.location);
    },
    updateLocation() {
      locationService.updateLocation(this, this.location);
    },
    deleteLocation() {
      locationService.deleteLocation(this, this.location);
    },
    getUserNameByID(id) {
      if (this.usersList && id) {
        const result = this.usersList.find((item) => item.id === id);
        if (result) {
          return result.username;
        }
        return '';
      }
      return '';
    }
  },
  created() {
    this.getLocations();
    usersService.getUsers(this, { onlyLoad: true });
    // Чтобы при создании новой локации не выбиралсь точка 0,0 (на экваторе)
    // используем в качестве координат по умолчанию координаты пользователя
    // если это возможно.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.newLocation.coords.lat = position.coords.latitude;
        this.newLocation.coords.lng = position.coords.longitude;
      });
    }
  },
  mounted() {
    if (this.$route.params.locationId) {
      this.locationSelectedInDashboard = this.$route.params.locationId;
    }
  }
};
</script>

<style lang="scss">
.location .btn {
  margin-bottom: 0;
}
.locations-list .action-row {
  min-width: 100px;
  text-align: right;
}
.locations-list tr {
  transition: background 0.15s;
  /* cursor: pointer */
}
.locations-list .row-read-only {
  transition: background 0.15s;
  cursor: default;
}
.locations-list tr:hover {
  background: rgba(236, 236, 236, 0.59);
}
.map {
  width: 100%;
}
// .location-table {
//   word-break: break-all;
//   word-wrap: break-word;
// }
.location-help {
  font-size: 0.6rem;
}

.root-location::after {
  content: '/';
  position: absolute;
  top: 4px;
  font-size: 1rem;
  line-height: 1.55;
  left: 15px;
  z-index: 10;
}

.location-heading {
  font-size: 18px;
}
</style>

<style lang="scss" scoped>
  .loader-danger-in-locations{
     &:after {
        border-color: red transparent red transparent !important;
     }
  }
.custom-loc-id-cursor {
  cursor: text !important;
}
.location-selected-on-map {
  background: #f4f4f4;
}
.location-word-break {
  word-wrap: break-word;
  word-break: break-all;
  max-width: 300px;
}

.custom-location-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.custom-location-logo-img {
  height: 150px;

  object-fit: cover;

  transition: opacity 0.3s;
}

.custom-location-logo-edit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  z-index: 10;
}

.custom-location-logo-field {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0;

  width: 100%;
  height: 100%;
  opacity: 0;
}
.custom-location-logo i {
  transition: opacity 0.3s;
}

.custom-location-logo:hover .custom-location-logo-img {
  opacity: 0.2;
}
.custom-location-logo:hover .custom-location-logo-edit {
  opacity: 0.9;
}

.custom-location-logo-icon:hover {
  opacity: 0.5;
}

.custom-location-logo-delete:hover {
  opacity: 0.5;
}

/*Change photo*/

.modal-photo {
  display: flex;
  justify-content: center;
}

.new-photo {
  width: 150px;
  height: 150px;

  object-fit: cover;
  border-radius: 100%;
}
.custom-logo-wrapper {
  min-height: 150px;
  min-width: 150px;
  display: flex;
  justify-content: center;
}
.custom-color-input {
  text-align: center;
  height: 1.6em;
}
.custom-color-input-color {
  height: 1.6em;
  margin-left: 0.2em;
}
.custom-color-wrapper {
  min-width: 50%;
}
.custom-color-input-label {
  max-width: 200px;
}
.custom-color-delete-color {
  font-size: 1.6em;
  margin-left: 0.2em;
}
.disable-delete-color {
  cursor: not-allowed;
}
</style>
